import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import { Layout } from "../../components/Layout";
import { CardMedia } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Seo from "../../components/Seo/Seo";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { CallbackBanner } from "../../components/CallbackBanner";
import DeskCleanImg from "../../images/services-page/cleaning_desk.jpeg";
import MopIcon from "../../assests/svg/cleaning-mop.svg";

import { useStyles } from "../../pagesStyles/aboutUs.styling";

const OfficeCleaning = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Office Cleaning",
    },
  ];

  const listItems = [
    "Washroom",
    "Specialist Cleaning",
    "Deep Cleaning",
    "Window Cleaning",
    "Carpet & Upholstery vacuuming",
    "Cleaning of Blinds",
    "Dusting and polishing",
    "Maintaining Covid Infection Control Guidelines",
  ];
  return (
    <Layout gutter marginTop breadcrumb={breadcrumbs}>
      <Seo title="Office Cleaning" />
      <section style={{ padding: desktop ? 0 : "0 5px"  }}>
        <Grid container direction={desktop ? "row" : "column"} spacing={5}>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              <strong>Office Cleaning </strong>
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              PCH Services are a family run business, providing high standards
              of cleaning services.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Helping you to protect your staff and your customers. We work with
              corporates to small independent businesses.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              We understand the importance of providing a safe environment for
              your staff to work in. Our office cleaning procedures ensure you
              have complete peace of mind.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Including full supply of all your consumables using “Green”
              products where possible.
            </Typography>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Slide direction="right" in timeout={700}>
              <CardMedia
                style={{ height: "60vh" }}
                image={DeskCleanImg}
                // alt={altTag}
                classes={{ root: classes.cardImageL }}
                component="img"
              />
            </Slide>
          </Grid>
        </Grid>
      </section>
      <section style={{ padding: desktop ? "5rem 0" : "2rem 5px"  }}>
        <Typography variant="h5" gutterBottom>
          <strong>
            {" "}
            We offer the following services for our office cleaning contracts:
          </strong>
        </Typography>
        <Grid container direction={desktop ? "row" : "column"}>
          <Grid item xs={desktop ? 6 : 12}>
            <List aria-label="office cleaning services">
              {listItems.map((item, index) => {
                return (
                  index + 1 <= listItems.length / 2 && (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <MopIcon style={{ width: "2rem" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <List aria-label="office cleaning services continued" style={{ paddingTop: !desktop && 0 }}>
              {listItems.map((item, index) => {
                return (
                  index + 1 > listItems.length / 2 && (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <MopIcon style={{ width: "2rem" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Grid>
        </Grid>
      </section>
      <CallbackBanner/>
    </Layout>
  );
};

export default OfficeCleaning;
